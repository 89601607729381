import _ from "underscore";

const iOs = [
  { io: "0", name: "Geofence", code: "default" },
  { io: "io0", name: "Geofence", code: "default" },
  { io: "io1", name: "Bilge Pump", code: "bilgepump" },
  { io: "io2", name: "Security", code: "security" },
  { io: "io252", name: "Backup Power", code: "current" },
  { io: "io3", name: "Shore Power", code: "tension" },
  { io: "io332", name: "Door Sensor it's OPEN", code: "intrusion" },
  { io: "io66", name: "Battery", code: "battery" },
  { io: "io72", name: "Temp 1", code: "thermometer" },
  { io: "io73", name: "Temp 2", code: "thermometer" },
  { io: "io76", name: "High Water", code: "inundation" },
  { io: "io9", name: "Battery 1", code: "battery" },
  { io: "io99", name: "Device Initialized", code: "default" },
  { io: "io999", name: "Private Message", code: "default" },
];

const ICONS = [
		'anchor2',
		'alert',
		'amperemeter',
		'anchor',
		'battery',
		'bilge',
		'bilgepump',
		'boat-90x90',
		'co2',
		'current',
		'default',
		'depositlevel',
		'engine',
		'fire',
		'gas',
		'gauge2',
		'globe',
		'gps',
		'highwater',
		'horario',
		'humidity',
		'info',
		'internalbattery',
		'intrusion',
		'inundation',
		'level',
		'life-ring',
		'lift',
		'map',
		'maxtemp',
		'movement',
		'oil',
		'pantalan',
		'rampa',
		'safari-pinned-tab',
		'security',
		'sentina',
		'signal',
		'surge',
		'tension',
		'thermometer',
		'time',
		'update',
		'vhf',
		'watertemp',
		'wifi',
		'wind',
		'winddirection',
		'wrench',
		'yatch',
	];


export default {

	getIoData(input){
		return _.findWhere(iOs, { io: input });
	},

  getIcons() {
    return ICONS;
  }

}
<template>
	<v-card dark class="backBlueDarken backBlueLight--text" outlined elevation="3" :loading="!loading">
		<v-data-table
			:headers="headers"
			:items="alertsOrderBy"
			:items-per-page="5"
			class="elevation-1 blue-grey"
			no-data-text="Loading Alerts ..."
			:search="search"
		>

			<!-- slot Top table -->
			<template v-slot:top>
				<v-toolbar flat color="blue-grey darken-2 white--text">
					<v-toolbar-title>Alerts</v-toolbar-title>
<!-- 					<v-spacer />
					<v-text-field
						dense
						v-model="search"
						label="Search"
						class="mt-4"
						solo
        	/> -->
				</v-toolbar>
			</template>
			<!-- Input Name -->
			<template v-slot:item.io="{ item }">
				{{ findIoData(item) }}
			</template>
			<!-- Timestamp -->
			<template v-slot:item.timestamp="{ item }">
				{{ item.timestamp | moment('MM/DD/YYYY (H:mm:ss)') }}
			</template>

		</v-data-table>
	</v-card>
</template>

<script>
	import _ from 'underscore';
	import inputsMixin from '@/mixins/inputsMixin.js'
	export default {
		name: 'ShowAlertsList',
		props: {
			inputs: Array,
			alerts: Array,
			loading: Boolean
		},
		data: ()=>({
			search: '',
			headers: [
        {text: 'Input', value: 'io', align: 'left'},
        {text: 'Alert', value: 'text', align: 'left'},
        {text: 'Date time', value: 'timestamp', align: 'center'},
      ],
		}),
		methods: {
			findIoData(item){
				let input = this.inputs.find(inp => inp.id == item.io)
				return input ? input.name : item.text
      },
		},
		computed: {
			alertsOrderBy(){
        return _.sortBy( this.alerts, 'timestamp' ).reverse();
      },
		}
	}
</script>

<style lang="scss" scoped>

</style>